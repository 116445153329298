import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppState } from '@/features/app/hooks'

export const BookingCheckoutDetails = () => {
  const { t } = useTranslation()
  const {
    appState: { mobileView },
  } = useAppState()
  const { booking } = useSelector((state: any) => state.booking.checkout)
  return (
    booking && (
      <div className="bg-white rounded-lg shadow-sm mb-8 p-4">
        {!mobileView && (
          <div className=" font-bold text-xl">
            {t('price')}: {(booking.total_amount / 100).toFixed(2)}€
          </div>
        )}
        <div className="text-md mt-2">
          <span className="font-bold">{t('checkout.title')}</span> {t('checkout.subtitle')}
        </div>
        <div className="text-md mt-1">{t('checkout.text')}</div>
      </div>
    )
  )
}
