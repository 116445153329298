import classNames from 'classnames'
import { useRef } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { Center, Container, Group, Rating, Space, Stack, Text } from '@mantine/core'
import { Underline } from '@/components/Elements/Icon/Underline'
import { useAppState } from '@/features/app/hooks'
import { CarouselNextArrow } from './CarouselNextArrow'
import { CarouselPrevArrow } from './CarouselPrevArrow'
import { Review } from './Review'
import useStyles from './SectionReviews.styles'

interface IProps {
  showReviews: boolean
}

export const SectionReviews = ({ showReviews }: IProps) => {
  const { t } = useTranslation()
  const carouselRef = useRef<Slider>(null)
  const { classes } = useStyles()
  const {
    appState: { mobileView },
  } = useAppState()

  const settings = {
    prevArrow: (
      <CarouselPrevArrow
        cn={classNames(classes.arrow, classes.arrowPrev)}
        handleClick={() => carouselRef?.current?.slickPrev()}
      />
    ),
    nextArrow: (
      <CarouselNextArrow
        cn={classNames(classes.arrow, classes.arrowNext)}
        handleClick={() => carouselRef?.current?.slickNext()}
      />
    ),
    appendDots: (dots: any) => <div>{dots}</div>,
    customPaging: (i: any) => <div />,
    dots: true,
    slidesToShow: mobileView ? 1 : 3,
    slidesToScroll: 1,
    infinite: false,
  }

  interface ReviewInterface {
    name: string
    text: string
  }
  const blokReviews: ReviewInterface[] = [
    {
      name: t('rating1.name'),
      text: t('rating1.text'),
    },
    {
      name: t('rating2.name'),
      text: t('rating2.text'),
    },
    {
      name: t('rating3.name'),
      text: t('rating3.text'),
    },
    {
      name: t('rating4.name'),
      text: t('rating4.text'),
    },
    {
      name: t('rating5.name'),
      text: t('rating5.text'),
    },
    {
      name: t('rating6.name'),
      text: t('rating6.text'),
    },
    {
      name: t('rating7.name'),
      text: t('rating7.text'),
    },
    {
      name: t('rating8.name'),
      text: t('rating8.text'),
    },
    {
      name: t('rating9.name'),
      text: t('rating9.text'),
    },
    {
      name: t('rating10.name'),
      text: t('rating10.text'),
    },
    {
      name: t('rating11.name'),
      text: t('rating11.text'),
    },
    {
      name: t('rating12.name'),
      text: t('rating12.text'),
    },
    {
      name: t('rating13.name'),
      text: t('rating13.text'),
    },
    {
      name: t('rating14.name'),
      text: t('rating14.text'),
    },
    {
      name: t('rating15.name'),
      text: t('rating15.text'),
    },
    {
      name: t('rating16.name'),
      text: t('rating16.text'),
    },
    {
      name: t('rating17.name'),
      text: t('rating17.text'),
    },
  ]

  const reviewsText = t('auth.reviews_title').split('<code>')

  return (
    <section id="reviews" className={classes.section}>
      <Container className={classes.container}>
        <div className={classes.sectionContent}>
          <Group className={'gap-2 justify-center mb-2'}>
            <h2 className={classes.title}>{ReactHtmlParser(reviewsText[0])}</h2>

            {reviewsText.length > 1 && (
              <Stack className={'gap-0 text-center'}>
                <h2 className={classes.titleColored}>{reviewsText[1]}</h2>
                <div className={classes.underline}>
                  <Underline />
                </div>
              </Stack>
            )}
            {reviewsText.length > 2 && (
              <h2 className={classes.title}>{ReactHtmlParser(reviewsText[2])}</h2>
            )}
          </Group>
          {!showReviews && (
            <>
              <Space h={'xl'} />
              <Center>
                <Stack className={'gap-2'}>
                  <p className={classes.reviewHeader}>{t('auth.rating_evaluation')}</p>
                  <Center>
                    <Group className={'gap-1'}>
                      <Rating fractions={3} value={4.66} readOnly />
                      <Text className={'mb-1'} weight={'bold'} size={'sm'}>
                        {'(4.7)'}
                      </Text>
                    </Group>
                  </Center>
                  <p className={classes.reviewText}>{t('auth.rating_evaluation_based')}</p>
                </Stack>
              </Center>
            </>
          )}
          {showReviews && (
            <>
              <Space h={'xl'} />
              <Slider {...settings} ref={carouselRef} className={classes.carousel}>
                {blokReviews.map((item, index) => (
                  <div key={index} className={classes.slide}>
                    <Review {...item} />
                  </div>
                ))}
              </Slider>
            </>
          )}
        </div>
      </Container>
    </section>
  )
}
