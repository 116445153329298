import moment from 'moment'
import { HOTEL_TYPE } from '@/const/hotel'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
//import { formHelper } from '@/features/profile/helpers'
import { DateUtils } from '@/utils'

//const { clientProfile: clientProfileHelper } = formHelper

const mapBookingTimePeriodValues = (type: BOOKING_TYPE, timetable = {}) => {
  const { time_start, time_end, same_days_time } = timetable as any

  let result: { time_start?: string; time_end?: string } = {}

  if (type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.ANIMATION || same_days_time) {
    result = {
      time_start: DateUtils.dateToTime(time_start),
      time_end: DateUtils.dateToTime(time_end),
    }
  }

  return result
}

const mapDaysValues = (days: {}, same_days_time: boolean) => {
  let result = {}

  Object.entries(days).forEach((entry) => {
    const [key, value] = entry
    const { enabled, time_start, time_end } = value as any
    let dayValue: { enabled: boolean; time_start?: string; time_end?: string } = {
      enabled: enabled,
    }

    if (enabled && !same_days_time) {
      dayValue = {
        ...dayValue,
        time_start: DateUtils.dateToTime(time_start),
        time_end: DateUtils.dateToTime(time_end),
      }
    }

    result = {
      ...result,
      [key]: dayValue,
    }
  })

  return result
}

const mapDaysScheduleValues = (type: BOOKING_TYPE, timetable = {}) => {
  const { same_weeks_schedule, days_schedule, same_days_time } = timetable as any

  let result: { days_schedule?: object } = {}

  if (
    type !== BOOKING_TYPE.ONETIME &&
    type !== BOOKING_TYPE.ANIMATION /*|| (type === BOOKING_TYPE.LIVE_IN && same_weeks_schedule)*/
  ) {
    result = {
      days_schedule: mapDaysValues(days_schedule, same_days_time),
    }
  }

  return result
}

const mapWeeksScheduleValues = (type: BOOKING_TYPE, timetable = {}) => {
  const { same_weeks_schedule, weeks_schedule, same_days_time } = timetable as any

  let result: { weeks_schedule?: any[] } = {}

  if (type !== BOOKING_TYPE.ONETIME && type !== BOOKING_TYPE.ANIMATION && !same_weeks_schedule) {
    let values: any[] = []

    weeks_schedule.forEach((week: Array<any>) => {
      const mapWeekValues = mapDaysValues(week, same_days_time)
      values = [...values, mapWeekValues]
    })

    result = {
      weeks_schedule: values,
    }
  }

  return result
}
const mapOnetimeRangesValues = (type: BOOKING_TYPE, timetable = {}) => {
  const { onetime_ranges } = timetable as any

  let result: { onetime_ranges?: any[] } = {}
  const rangesValue = onetime_ranges.map(
    ({
      range_date_start,
      range_date_end,
      range_time_start,
      range_time_end,
      ...restItem
    }: {
      range_date_start: Date
      range_date_end: Date
      range_time_start: Date
      range_time_end: Date
    }) => ({
      ...restItem,
      range_date_start: DateUtils.formatDateToServerFormat(range_date_start),
      range_date_end: DateUtils.formatDateToServerFormat(
        type === BOOKING_TYPE.ONETIME
          ? calcEndDateOneTimeRange(range_date_start, range_time_start, range_time_end)
          : range_date_end
      ),
      range_time_start: DateUtils.dateToTime(range_time_start),
      range_time_end: DateUtils.dateToTime(range_time_end),
    })
  )

  result = {
    onetime_ranges: rangesValue,
  }
  return result
}

function calcEndDateOneTimeRange(date_start: Date, timeStart: Date, timeEnd: Date) {
  let calcDateEnd = moment(date_start)
  if (
    moment(date_start).hour(moment(timeStart).hour()) >
    moment(date_start).hour(moment(timeEnd).hour())
  ) {
    console.log('added one day')
    calcDateEnd = moment(date_start).add(1, 'day')
  }
  return calcDateEnd
}

const mapTimetableValues = (type: BOOKING_TYPE, timetable = {}) => {
  const { same_days_time, same_weeks_schedule, exception_dates, onetime_ranges } = timetable as any

  const bookingTimePeriodValues = mapBookingTimePeriodValues(type, timetable)

  const daysScheduleValues = mapDaysScheduleValues(type, timetable)

  const weeksScheduleValues = mapWeeksScheduleValues(type, timetable)

  const onetimeRangesValues = mapOnetimeRangesValues(type, timetable)

  return {
    ...bookingTimePeriodValues,
    same_days_time,
    same_weeks_schedule,
    exception_dates: exception_dates.map((date: Date) => DateUtils.formatDateToServerFormat(date)),
    ...daysScheduleValues,
    ...weeksScheduleValues,
    ...onetimeRangesValues,
  }
}

export const mapSubmitValues = (values = {}, onetime: boolean) => {
  const {
    //city_id,
    //city_type,
    //city_description,
    //country_id,
    is_hotel,
    hotel_id,
    hotel_name,
    type,
    date_start,
    date_end,
    hours_per_week,
    days_per_week,
    living_in,
    timetable,
    booking_children,
    onetime_ranges,
    has_special_needs,
    special_needs,
    phone,
    ...restValues
  } = values as any

  /*const cityValues = clientProfileHelper.mapCitySubmitValue({
    city_id,
    city_type,
    city_description,
    country_id,
  })*/

  const childrenValue = booking_children.map(({ birthday, ...restItem }: { birthday: Date }) => ({
    ...restItem,
    birthday: DateUtils.formatDateToServerFormat(birthday),
  }))

  let calcDateEnd = moment(onetime ? date_start : date_end)
  if (onetime) {
    if (
      moment(date_start).hour(moment(timetable.time_start).hour()) >
      moment(date_start).hour(moment(timetable.time_end).hour())
    ) {
      console.log('added one day')
      calcDateEnd = moment(date_start).add(1, 'day')
    }
  }
  let result = {
    ...restValues,
    //...cityValues,
    is_hotel,
    hotel_name: is_hotel && hotel_id == HOTEL_TYPE.OTHER ? hotel_name : '',
    type,
    hours_per_week,
    days_per_week,
    living_in,
    date_start: DateUtils.formatDateToServerFormat(date_start),
    date_end: DateUtils.formatDateToServerFormat(calcDateEnd),
    timetable: mapTimetableValues(type, timetable),
    booking_children: childrenValue,
    has_special_needs,
    special_needs: has_special_needs ? special_needs : '',
    phone,
  }

  if (hotel_id !== HOTEL_TYPE.OTHER) {
    result = {
      ...result,
      hotel_id,
    }
  }

  return result
}

export const mapSubmitValuesCareDetails = (values = {}) => {
  const {
    booking_children,
    has_special_needs,
    special_needs,
    phone,
    address,
    booking_id,
    care_expectations,
  } = values as any
  const childrenValue = booking_children.map(({ birthday, ...restItem }: { birthday: Date }) => ({
    ...restItem,
    birthday: DateUtils.formatDateToServerFormat(birthday),
  }))

  return {
    care_expectations,
    booking_id,
    booking_children: childrenValue,
    phone,
    has_special_needs,
    address,
    special_needs: has_special_needs ? special_needs : '',
  }
}
