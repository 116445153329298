import moment from 'moment/moment'
import * as Yup from 'yup'
import { timetableHelper } from '@/features/booking/helpers'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'

const timePeriodRuleTest = {
  name: 'timePeriodDependRequired',
  exclusive: false,
  params: {},
  message: 'field.error.required',
  test: function (value: Date | null | undefined, context: Yup.TestContext & any) {
    const sameDaysTime = context.parent.same_days_time

    const { from } = context
    const { type } = from[1].value

    const isOnetime = type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.ANIMATION

    if (isOnetime || (!isOnetime && sameDaysTime)) {
      return !!value
    }

    return true
  },
}

const timeEndPeriodRuleTest = {
  name: 'timeEndPeriodDependRequired',
  exclusive: false,
  params: {},
  message: 'field.error.time.after-or-equal',
  test: function (value: Date | null | undefined, context: Yup.TestContext & any) {
    const { from } = context
    const { date_start, date_end, type } = from[1].value
    if (!value || type == BOOKING_TYPE.ONETIME) return true

    const startTime = moment(context.parent.time_start)
    const timeEnd = moment(value)

    return moment(date_end).hour(timeEnd.hour()) > moment(date_start).hour(startTime.hour())
  },
}

const timeEndPeriod3hoursRuleTest = {
  name: 'timeEndPeriod3hours',
  exclusive: false,
  params: {},
  message: 'field.error.date.min-3-hours',
  test: function (value: Date | null | undefined, context: Yup.TestContext & any) {
    const { from } = context
    const { is_hotel, date_start, date_end, type } = from[1].value
    if (type != BOOKING_TYPE.ONETIME && (!is_hotel || type == BOOKING_TYPE.PERMANENT_NANNY))
      return true
    if (context.parent.time_start && date_start) {
      const timeStart = moment(context.parent.time_start)
      const timeEnd = moment(value)
      const diff = moment(type == BOOKING_TYPE.ONETIME ? date_start : date_end)
        .hour(timeEnd.hour())
        .diff(moment(date_start).hour(timeStart.hour()))

      const duration = moment.duration(diff)
      return duration.asHours() >= 3 || (duration.asHours() < 0 && duration.asHours() >= -21)
    }
    return true
  },
}

const getTimePeriodValidations = () => ({
  time_start: Yup.date().nullable().test(timePeriodRuleTest),
  time_end: Yup.date()
    .nullable()
    .test(timePeriodRuleTest)
    .test(timeEndPeriodRuleTest)
    .test(timeEndPeriod3hoursRuleTest),
})

const daysScheduleTimeRuleTest = {
  name: 'dependRequired',
  exclusive: false,
  params: {},
  message: 'field.error.required',
  test: function (value: Date | null | undefined, context: Yup.TestContext & any) {
    const dayEnabled = context.parent.enabled

    const { from } = context
    const { same_weeks_schedule, same_days_time } = from[2].value

    if (same_weeks_schedule && dayEnabled && !same_days_time) {
      return !!value
    }

    return true
  },
}

const isDayPicked = (weekDays: object) => {
  const weekDaysList = Object.entries(weekDays)

  let status = false

  for (const [dayKey, dayValue] of weekDaysList) {
    const { enabled } = dayValue as any
    if (enabled) {
      status = true
      break
    }
  }

  return status
}

const getDaysScheduleValidations = () => {
  const weekdays = timetableHelper.weekdays.getWeekDays()
  const validation = Object.assign(
    {},
    ...weekdays.map(({ key }) => ({
      [key]: Yup.object().shape({
        time_start: Yup.date().nullable().test(daysScheduleTimeRuleTest),
        time_end: Yup.date().nullable().test(daysScheduleTimeRuleTest),
      }),
    }))
  )

  return {
    days_schedule: Yup.object()
      .shape({
        ...validation,
      })
      .test(
        'days_schedule-min',
        'no_selected_days',
        (value: object | null | undefined, context: Yup.TestContext & any) => {
          const { parent, from } = context
          const { same_weeks_schedule } = parent

          const { type } = from[2].value

          const isNotOnetime = type !== BOOKING_TYPE.ONETIME && type !== BOOKING_TYPE.ANIMATION

          if (value && isNotOnetime && same_weeks_schedule) {
            // check - must have one day picked
            return isDayPicked(value)
          }

          return true
        }
      ),
  }
}

const weeksScheduleTimeRuleTest = {
  name: 'dependRequired',
  exclusive: false,
  params: {},
  message: 'field.error.required',
  test: function (value: Date | null | undefined, context: Yup.TestContext & any) {
    const dayEnabled = context.parent.enabled

    const { from } = context

    const { same_weeks_schedule, same_days_time } = from[2].value

    if (!same_weeks_schedule && dayEnabled && !same_days_time) {
      return !!value
    }

    return true
  },
}

const getWeeksScheduleValidations = () => {
  const weekdays = timetableHelper.weekdays.getWeekDays()
  // console.log('WEEK DAYS: ' + JSON.stringify(weekdays))
  const validation = Object.assign(
    {},
    ...weekdays.map(({ key }) => ({
      [key]: Yup.object().shape({
        time_start: Yup.date().nullable().test(weeksScheduleTimeRuleTest),
        time_end: Yup.date().nullable().test(weeksScheduleTimeRuleTest),
      }),
    }))
  )

  return {
    weeks_schedule: Yup.array()
      .of(
        Yup.object().shape({
          ...validation,
        })
      )
      .test(
        'weeks_schedule-min',
        'no_selected_days',
        (value: any[] | null | undefined, context: Yup.TestContext & any) => {
          const { parent, from } = context
          const { same_weeks_schedule } = parent

          const { type } = from[1].value

          const isNotOnetime =
            type !== BOOKING_TYPE.ONETIME &&
            type !== BOOKING_TYPE.ANIMATION &&
            type != BOOKING_TYPE.PERMANENT_NANNY

          if (value && isNotOnetime && !same_weeks_schedule) {
            // check - must have one day picked
            let status = false

            for (const week of value) {
              status = isDayPicked(week)

              // return if find first enabled day
              if (status) {
                break
              }
            }
            return status
          }

          return true
        }
      )
      .test(
        'weeks_schedule-min_per_week',
        'no_selected_days_every_week',
        (value: any[] | null | undefined, context: Yup.TestContext & any) => {
          const { parent, from } = context
          const { same_weeks_schedule } = parent

          const { type } = from[1].value

          const isNotOnetime =
            type !== BOOKING_TYPE.ONETIME &&
            type !== BOOKING_TYPE.ANIMATION &&
            type != BOOKING_TYPE.PERMANENT_NANNY

          if (value && isNotOnetime && !same_weeks_schedule) {
            // check - must have one day picked
            let status = false

            for (const week of value) {
              status = isDayPicked(week)

              // return if find first enabled day
              if (!status) {
                return false
              }
            }
            return status
          }

          return true
        }
      ),
  }
}

const getOnetimeRangeValidations = () => ({
  onetime_ranges: Yup.array().of(
    Yup.object().shape({
      range_time_start: Yup.date().nullable().required('field.error.required'),
      range_time_end: Yup.date().nullable().required('field.error.required'),
      range_date_start: Yup.date()
        .nullable()
        .required('field.error.required')
        .test(
          'onetime-ranges-min2',
          'field.error.date.after-or-equal.main-date',
          (value: any, context: Yup.TestContext & any) => {
            const { parent, from } = context
            const { time_start, time_end } = from[1].value
            const { date_start } = from[2].value

            let realDateEnd = moment(date_start)
            if (moment(time_start).isBefore(moment(time_end)))
              realDateEnd = moment(realDateEnd).add(1, 'day')

            if (value) {
              if (parent.range_time_end) {
                const rangeTimeStart = moment(parent.range_time_start)
                const timeEnd = moment(time_end)
                return (
                  moment(value).hour(rangeTimeStart.hour()).diff(realDateEnd.hour(timeEnd.hour())) >
                  0
                )
              }
              return moment(value).diff(realDateEnd) >= 0
            }
            return true
          }
        ),
      range_date_end: Yup.date()
        .nullable()
        .required('field.error.required')
        .test(
          'onetime-ranges-min1',
          'field.error.date.after-or-equal',
          (value: any, context: Yup.TestContext & any) => {
            const { parent, from } = context
            if (parent.type === BOOKING_TYPE.ONETIME) return true
            if (value && parent.range_date_start) {
              if (parent.range_time_end) {
                const timeStart = moment(parent.range_time_start)
                const timeEnd = moment(parent.range_time_end)
                return (
                  moment(value)
                    .hour(timeEnd.hour())
                    .diff(moment(parent.range_date_start).hour(timeStart.hour())) > 0
                )
              }
              return moment(value).diff(moment(parent.range_date_start)) >= 0
            }
            return true
          }
        )
        .test(
          'min3hours',
          'field.error.date.min-3-hours',
          (value: any, context: Yup.TestContext & any) => {
            const { parent, from } = context
            const { is_hotel } = from[2].value
            if (value && is_hotel && parent.range_date_start) {
              if (parent.range_time_end) {
                const timeStart = moment(parent.range_time_start)
                const timeEnd = moment(parent.range_time_end)
                const diff = moment(value)
                  .hour(timeEnd.hour())
                  .diff(moment(parent.range_date_start).hour(timeStart.hour()))

                const duration = moment.duration(diff)
                return duration.asHours() >= 3
              }
              return true
            }
            return true
          }
        ),
    }) /*
      .test(
        'onetime-ranges-min2',
        'field.error.date.after-or-equal',
        (value: any, context: Yup.TestContext & any) => {
          const { parent, from } = context
          const { date_end, time_end } = parent

          console.log('RANGE PARENT:' + JSON.stringify(parent))
          console.log('RANGE FROM:' + JSON.stringify(from))
          return (
            moment(value.range_date_start)
              .hour(value.range_time_start.hour())
              .diff(moment(date_end).hour(time_end.hour())) > 0
          )
        }
      )*/
  ),
})
export const validationHelper = {
  getTimePeriodValidations,
  getDaysScheduleValidations,
  getWeeksScheduleValidations,
  getOnetimeRangeValidations,
}
