import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { debounce, isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Group, Tooltip } from '@mantine/core'
import { Alert, Button, ContentCard } from '@/components/Elements'
import { HOTEL_TYPE } from '@/const/hotel'
import { useAppState } from '@/features/app/hooks'
import { useAuth } from '@/features/auth'
import { authLoginPA } from '@/features/auth/store'
import useStyles from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/BookingWizardForm.styles'
import { DataForm } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/GeneralForm/DataForm'
import { GeneralFormNoLogin } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/Forms/GeneralForm/GeneralFormNoLogin'
import {
  getDaysScheduleDefaultValues,
  mapSubmitValues,
} from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/helpers'
import { getValidationSchema } from '@/features/booking/components/Booking/BookingContainer/BookingWizardForm/validation'
import { bookingCreatePA } from '@/features/booking/store'
import { checkoutState } from '@/features/booking/store/checkout.slice'
import { bookingCreateNoLoginPA } from '@/features/booking/store/createNoLogin/saga'
import { previousRequestState } from '@/features/booking/store/previousRequest.slice'
import { bookingAPI } from '@/features/bookings/api'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { useBookingOptions } from '@/features/bookings/hooks'
import { useUser } from '@/features/user'
import { useFormSubmit, useLang, useNotify } from '@/hooks'
import { BookingLocation } from '../../types'

interface IProps {
  initialValuesBooking: { general: object; timetable: object }
  initialValuesDetails: { general: object }
  previousRequest: any
  bookingType: BOOKING_TYPE
}
export const GeneralForm = ({
  initialValuesBooking,
  initialValuesDetails,
  previousRequest,
  bookingType,
}: IProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const { isLoggedIn } = useAuth()
  const viewport = useRef<HTMLDivElement>()
  const {
    appState: { mobileView },
  } = useAppState()

  const [showUserDataForm, setShowUserDataForm] = useState(false)
  const [location, setLocation] = useState<BookingLocation | null>(null)
  const [locationNotFound, setLocationNotFound] = useState<boolean>(false)
  const [calculatingPrice, setCalculatingPrice] = useState<boolean>(false)
  const [priceError, setPriceError] = useState<boolean>(false)
  const [calculatedPrice, setCalculatedPrice] = useState<number>(0)
  const [priceAnalysis, setPriceAnalysis] = useState<any>({})
  const { getType } = useBookingOptions()
  const locationRef = useRef<BookingLocation | null>(null)
  const skipNextCalcRef = useRef<boolean>(false)
  const previousPriceRequestRef = useRef<any>({})

  const { lang } = useLang()
  const { user } = useUser()
  const navigate = useNavigate()

  const goNext = () => {
    if (bookingType === BOOKING_TYPE.ONETIME || bookingType === BOOKING_TYPE.REGULAR)
      navigate('/booking/' + getType(bookingType).path + '/checkout', { replace: true })
    else navigate('/booking/' + getType(bookingType).path + '/care-details', { replace: true })
  }

  const defaultValues = previousRequest
    ? {
        ...initialValuesBooking?.general,
        ...initialValuesDetails?.general,
        first_name: '',
        last_name: '',
        email: '',

        //city_id: previousRequest.city_id,
        //city_type: previousRequest.city_type,
        //city_description: previousRequest.city_description,
        //country_id: previousRequest.country_id,
        lat: previousRequest.lat,
        lng: previousRequest.lng,
        is_hotel: previousRequest.is_hotel,
        hotel_id: previousRequest.hotel_id,
        // hotel_name: '', // hidden field, depend on 'is_hotel'
        //address: previousRequest.address,
        //floor: previousRequest.floor,
        //door_number: previousRequest.door_number,
        zip: previousRequest.zip,
        type: bookingType,
        date_start: null,
        date_end: null,
        //comments: previousRequest.comments,
        hours_per_week: previousRequest.hours_per_week,
        days_per_week: previousRequest.days_per_week,
        living_in: previousRequest.living_in,
        timetable: {
          time_start: null,
          time_end: null,
          same_weeks_schedule: false,
          same_days_time: false,
          weeks_schedule: [],
          days_schedule: getDaysScheduleDefaultValues(),
          exception_dates: [],
          onetime_ranges: [],
          ...initialValuesBooking?.timetable,
        },
        booking_children: previousRequest.booking_children,
        primary_language_id: previousRequest.primary_language_id,
        secondary_language_id: previousRequest.secondary_language_id,
        has_special_needs: previousRequest.has_special_needs,
        special_needs: previousRequest.special_needs || '',
        //other_info: previousRequest.other_info,
        care_expectations: previousRequest.care_expectations,
      }
    : {
        first_name: '',
        last_name: '',
        email: '',
        //city_id: null,
        //city_type: '',
        //city_description: '',
        //country_id: null,
        lat: 0,
        lng: 0,
        is_hotel: false,
        hotel_id: null,
        // hotel_name: '', // hidden field, depend on 'is_hotel'
        //address: '',
        //floor: '',
        //door_number: '',
        zip: '',
        type: bookingType,
        date_start: null,
        date_end: null,
        //comments: '',
        living_in: false,
        hours_per_week: null,
        days_per_week: null,
        timetable: {
          time_start: null,
          time_end: null,
          same_weeks_schedule: false,
          same_days_time: false,
          weeks_schedule: [],
          days_schedule: getDaysScheduleDefaultValues(),
          exception_dates: [],
          onetime_ranges: [],
          ...initialValuesBooking?.timetable,
        },
        booking_children: [],
        primary_language_id: lang == 'en' ? 2 : 1,
        secondary_language_id: null,
        has_special_needs: false,
        phone: null,
        // special_needs: '', // hidden field, depend on 'has_special_needs'
        //other_info: '',
        care_expectations: '',

        ...initialValuesBooking?.general,
        ...initialValuesDetails?.general,
      }
  const currentValidationSchema = getValidationSchema({ step: !showUserDataForm ? 0 : 1 })

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(currentValidationSchema),
  })

  const {
    handleSubmit,
    trigger,
    setError,
    watch,
    formState: { errors, isSubmitting },
    getValues,
    setValue,
  } = methods

  const watchValuesPrice = watch([
    'is_hotel',
    'hotel_id',
    'timetable',
    'has_special_needs',
    'living_in',
    'days_per_week',
    'hours_per_week',
  ])
  const watchType = watch('type')
  const watchHotelId = watch('hotel_id')
  const watchIsHotel = watch('is_hotel')

  const onLocationChange = (data: BookingLocation | null) => {
    setLocation(data)
  }

  const onLocationNotFound = (data: boolean) => {
    setLocationNotFound(data)
  }

  const scrollToTop = () => {
    if (viewport?.current) {
      viewport.current.scrollTo({ top: 0 })
    } else {
      window.scrollTo(0, 0)
    }
  }
  const isZipValid = (zip: string) => {
    const re = new RegExp('^[0-9]{4}-[0-9]{3}$')
    return re.test(zip)
  }

  const getCalculatedPrice = async () => {
    setCalculatingPrice(true)
    const hotelId = getValues('hotel_id')
    const isHotel = getValues('is_hotel')
    const hoursPerWeek = getValues('hours_per_week')
    const daysPerWeek = getValues('days_per_week')
    const livingIn = getValues('living_in')
    const type = getValues('type')
    const zip = getValues('zip')

    //console.log(`getCalculatedPrice type:` + type + ' isHotel:' + isHotel + ' hotelId:' + hotelId + ' zip:' + zip)
    if (type === BOOKING_TYPE.ONETIME || type === BOOKING_TYPE.REGULAR) {
      if (
        (locationRef.current &&
          locationRef.current.position &&
          locationRef.current.position.lat &&
          locationRef.current.position.lng &&
          locationRef.current.position.lat !== 0 &&
          locationRef.current.position.lng !== 0 &&
          isZipValid(zip) &&
          (!isHotel || (hotelId && hotelId === HOTEL_TYPE.OTHER))) ||
        (isHotel && hotelId && hotelId !== HOTEL_TYPE.OTHER)
      ) {
        //console.log('calc values')
        const values = {
          ...mapSubmitValues(getValues(), type == BOOKING_TYPE.ONETIME),
          ...locationRef.current?.position,
        }
        if (!isEqual(previousPriceRequestRef.current, values)) {
          previousPriceRequestRef.current = values
          //console.log('price temp values not equal:' + JSON.stringify(values))
          let calcPrice =
            values.date_end !== 'Invalid date' &&
            values.data_start !== 'Invalid date' &&
            values.timetable.time_end !== 'Invalid date' &&
            values.timetable.time_start !== 'Invalid date' &&
            (values.type != BOOKING_TYPE.PERMANENT_NANNY ||
              (livingIn && daysPerWeek) ||
              (!livingIn && hoursPerWeek))

          //console.log('calc price 1:' + calcPrice)
          values.timetable.onetime_ranges.forEach(function (value: any) {
            calcPrice =
              calcPrice &&
              value.range_time_start !== 'Invalid date' &&
              value.range_time_end !== 'Invalid date' &&
              value.range_date_start !== 'Invalid date' &&
              (type === BOOKING_TYPE.ONETIME || value.range_date_end !== 'Invalid date')
          })
          if (
            calcPrice &&
            values.timetable.time_end !== 'Invalid date' &&
            values.timetable.time_start !== 'Invalid date'
          ) {
            //console.log('values:' + JSON.stringify(values))
            if (values.type === BOOKING_TYPE.ONETIME || values.type === BOOKING_TYPE.REGULAR) {
              // TODO remove this when calculator is implemented
              //console.log('price values: ' + JSON.stringify(values))
              try {
                const { data } = await bookingAPI.getCalculatedPrice(isLoggedIn, values)
                setCalculatedPrice(data.price)
                setPriceError(false)
                if (
                  !priceAnalysis ||
                  JSON.stringify(data.bookingTableInformations) !== JSON.stringify(priceAnalysis)
                ) {
                  setPriceAnalysis(data.bookingTableInformations)
                }
                skipNextCalcRef.current = true
              } catch (e) {
                if (!priceError) {
                  showNotification({
                    type: 'error',
                    message: t('price_calc_error'),
                  })
                  setPriceError(true)
                  setCalculatedPrice(0)
                  setPriceAnalysis({})
                  skipNextCalcRef.current = true
                }
              }
            }
          }
        } else {
          //console.log('price temp is equal')
        }
      } else {
        //console.log('price values: SET 0')
        setCalculatedPrice(0)
        setPriceAnalysis({})
        previousPriceRequestRef.current = {}
        skipNextCalcRef.current = true
      }
    }
    setCalculatingPrice(false)
  }

  const debouncedCalcPrice = React.useCallback(debounce(getCalculatedPrice, 500), [])

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    if (skipNextCalcRef.current) {
      skipNextCalcRef.current = false
    } else {
      locationRef.current = location
      debouncedCalcPrice()
    }
  }, [watchValuesPrice, location])

  useEffect(() => {
    if (watchType != BOOKING_TYPE.PERMANENT_NANNY) {
      setValue('hours_per_week', null)
      setValue('days_per_week', null)
      setValue('living_in', false)
    }
    if (watchType == BOOKING_TYPE.ANIMATION) {
      setValue('is_hotel', false)
    }
    if (watchType == BOOKING_TYPE.REGULAR) {
      setValue('timetable.onetime_ranges', [])
    }
  }, [watchType])

  const showUserForm = async () => {
    const isStepValid = await trigger()
    if (isStepValid && (location || (watchIsHotel && !!watchHotelId)) && !priceError) {
      setShowUserDataForm(true)
    } else if (priceError) {
      showNotification({
        type: 'error',
        message: t('price_calc_error'),
      })
    } else {
      showNotification({
        type: 'error',
        message: t('please_fill_all_required_fields'),
      })
    }
  }

  const onSubmitBooking = async (values: any) => {
    let data
    if (!user) {
      values.price_analysis = priceAnalysis
      data = await dispatch(bookingCreateNoLoginPA.request(values))
      if (data && data.token) {
        //console.log('login user')
        await dispatch(authLoginPA.request({ email: values.email, token: data?.token }))
        data.booking.just_created_user = true
      } else {
        data.booking.just_created_user = false
      }
      await dispatch(checkoutState.setBooking(data.booking))
    } else {
      values.price_analysis = priceAnalysis
      data = await dispatch(bookingCreatePA.request(values))
      data.just_created_user = false
      await dispatch(checkoutState.setBooking(data))
    }
    if (previousRequest) {
      await dispatch(previousRequestState.setBooking(null))
    }
    goNext()
  }

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: onSubmitBooking,
    setError,
  })

  const onError = (errors: any, e: any) => {
    showNotification({
      type: 'error',
      message: t('please_fill_all_required_fields'),
    })
  }

  const onSubmit: SubmitHandler<any> = async (data) => {
    const isStepValid = await trigger()
    if (isStepValid && (location || (watchIsHotel && !!watchHotelId)) && !priceError) {
      try {
        const values = {
          ...mapSubmitValues(data, watchType == BOOKING_TYPE.ONETIME),
          ...location?.position,
          lang,
        }
        await onFormSubmit(values)
        // eslint-disable-next-line
      } catch (err: any) {
        //console.log('error ' + JSON.stringify(err))
        const error = err?.message || ''
        showNotification({
          type: 'error',
          message: t('error_submitting_request') + ' - ' + error,
        })
      }
    } else if (priceError) {
      showNotification({
        type: 'error',
        message: t('price_calc_error'),
      })
    } else if (!(location || (watchIsHotel && !!watchHotelId))) {
      showNotification({
        type: 'error',
        message: t('location_check_error'),
      })
    } else {
      showNotification({
        type: 'error',
        message: t('please_fill_all_required_fields'),
      })
    }
  }

  const payDisabled =
    calculatingPrice ||
    (calculatedPrice < 0.01 &&
      (watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR))

  const renderVat = () => {
    const priceWithoutVAT = calculatedPrice / (1 + priceAnalysis['Vat'] / 100)

    const vatAmount = priceWithoutVAT * (priceAnalysis['Vat'] / 100)
    return vatAmount.toFixed(2)
  }

  const renderTooltip = () => {
    return (
      <>
        {priceAnalysis && !!priceAnalysis['Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.hours') + ': ' + priceAnalysis['Hours']} *</span>
            <span>{priceAnalysis['Amount']}€</span>
            <span>= {priceAnalysis['Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivities Price'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.festivities') + ': ' + priceAnalysis['Festivities Price']}€</span>
          </div>
        )}
        {/*{priceAnalysis && !!priceAnalysis['Morning Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.morning_hours') + ': ' + priceAnalysis['Morning Hours']} *</span>
            <span>{priceAnalysis['Morning Amount']}€</span>
            <span>= {priceAnalysis['Morning Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivities Price Morning'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.festivities_morning') + ': ' + priceAnalysis['Festivities Price Morning']}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Evening Hours'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.evening_hours') + ': ' + priceAnalysis['Evening Hours']} *</span>
            <span>{priceAnalysis['Evening Amount']}€</span>
            <span>= {priceAnalysis['Evening Price']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Festivities Price Evening'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.festivities_evening') + ': ' + priceAnalysis['Festivities Price Evening']}€
            </span>
          </div>
        )}*/}
        {priceAnalysis && !!priceAnalysis['Distance Price'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.mileage_fee') + ': ' + priceAnalysis['Distance Price'].toFixed(2)}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Urgency Fee'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.urgency_fee') + ': ' + priceAnalysis['Urgency Fee']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Special Needs Fee'] && (
          <div className="flex gap-1 font-bold">
            <span>
              {t('price.special_needs_fee') + ': ' + priceAnalysis['Special Needs Amount']}€
            </span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Bonus'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.bonus') + ': ' + priceAnalysis['Bonus']}€</span>
          </div>
        )}
        {priceAnalysis && !!priceAnalysis['Vat'] && (
          <div className="flex gap-1 font-bold">
            <span>{t('price.vat') + ': ' + renderVat()}€</span>
          </div>
        )}
        {priceAnalysis &&
          !!priceAnalysis['pricesPerMonth'] &&
          Object.keys(priceAnalysis['pricesPerMonth']).length > 1 && (
            <>
              <div className="flex gap-1 mt-2 font-bold">
                <span>{t('price.per_month') + ''}</span>
              </div>
              {Object.keys(priceAnalysis['pricesPerMonth']).map((key, index) => (
                <>
                  {index > 0 && (
                    <div key={index} className="flex gap-1 font-bold">
                      <span>
                        {t('month') + ' '}
                        {key + ': ' + priceAnalysis['pricesPerMonth'][key].toFixed(2)}€
                      </span>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
      </>
    )
  }

  const renderFooter = () => {
    return (
      <div
        className={classNames(
          classes.footerPriceMobile,
          'flex',
          'justify-between',
          'items-center',
          {
            'sticky-footer': mobileView,
            'z-index': mobileView,
          }
        )}
      >
        {((watchType !== BOOKING_TYPE.ONETIME && watchType !== BOOKING_TYPE.REGULAR) ||
          showUserDataForm) && <div></div>}

        {mobileView &&
          (watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR) &&
          !showUserDataForm && (
            <div className={'flex flex-col'}>
              <Tooltip
                label={renderTooltip()}
                position="top"
                //withArrow
                //arrowSize={6}
                multiline
                events={{ hover: true, touch: true, focus: false }}
                disabled={calculatedPrice < 0.01}
              >
                <h2 className="flex gap-4 items-center cursor-pointer">
                  <span className={classes.price}>
                    {t(watchType === BOOKING_TYPE.REGULAR ? 'price.1st_month' : 'price')}
                  </span>
                  <i className="pi pi-info-circle"></i>
                </h2>
              </Tooltip>
              <h2 className="flex gap-4 items-center cursor-pointer" style={{ height: '30px' }}>
                {!calculatingPrice ? (
                  <span>€{calculatedPrice?.toFixed(2)}</span>
                ) : (
                  <i className="pi pi-spin pi-spinner"></i>
                )}
              </h2>
            </div>
          )}

        {!mobileView &&
          (watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR) &&
          !showUserDataForm && (
            <Tooltip
              label={renderTooltip()}
              position="top"
              withArrow
              //arrowSize={6}
              multiline
              events={{ hover: true, touch: true, focus: false }}
              disabled={calculatedPrice < 0.01}
            >
              <h2 className="flex gap-4 items-center cursor-pointer">
                <span>
                  {t(watchType === BOOKING_TYPE.REGULAR ? 'price.1st_month' : 'price')}:{' '}
                  {!calculatingPrice ? (
                    <>{calculatedPrice?.toFixed(2)}€</>
                  ) : (
                    <i className="pi pi-spin pi-spinner"></i>
                  )}
                </span>
                <i className="pi pi-info-circle"></i>
              </h2>
            </Tooltip>
          )}

        <Group>
          {/*step !== 0 && !mobileView && (
            <Button
              disabled={isSubmitting}
              variant="default"
              color="dark"
              leftIcon={<IconChevronLeft />}
              onClick={handleBack}
            >
              {t('back')}
            </Button>
          )*/}
          {(user || showUserDataForm) && (
            <Button
              loading={isSubmitting}
              disabled={payDisabled}
              onClick={handleSubmit(onSubmit, onError)}
            >
              {watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR
                ? t('confirm_and_pay')
                : t('booking_confirm')}
            </Button>
          )}
          {!user && !showUserDataForm && (
            <Button onClick={showUserForm} disabled={payDisabled}>
              {watchType === BOOKING_TYPE.ONETIME || watchType === BOOKING_TYPE.REGULAR
                ? t('confirm_and_pay')
                : t('booking_confirm')}
            </Button>
          )}
        </Group>
      </div>
    )
  }

  return (
    <FormProvider {...methods}>
      <form className={mobileView ? '' : 'h-full'}>
        <ContentCard
          title={t(showUserDataForm ? 'fill_contact_details' : 'book_baby_sister').replace(
            '%BOOKING_TYPE%',
            getType(watchType)?.title
          )}
          titleInfo={showUserDataForm ? undefined : t('booking_title_info')}
          footer={renderFooter()}
          scrollable={!mobileView}
          viewport={viewport}
          className={classes.cardWrapper}
        >
          <div className={classes.wrapper}>
            {submitError && (
              <Alert type={'error'} mb={'sm'}>
                {submitError?.message || t('error')}
              </Alert>
            )}
            {!showUserDataForm ? (
              <DataForm
                location={location}
                onLocationChange={onLocationChange}
                locationNotFound={locationNotFound}
                onLocationNotFound={onLocationNotFound}
              />
            ) : (
              <GeneralFormNoLogin />
            )}
          </div>
        </ContentCard>
      </form>
    </FormProvider>
  )
}
