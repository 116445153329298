import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Stack } from '@mantine/core'
import { CheckboxFieldController, SelectController } from '@/components/FormElements'
import { useAppState } from '@/features/app/hooks'

export const PermanentNannyFormSection = () => {
  const { t } = useTranslation()

  const { control, watch } = useFormContext()
  const watchLivingIn = watch('living_in')

  const {
    appState: { mobileView },
  } = useAppState()

  const optionsHoursPerWeekSelect = [
    {
      value: '0-16',
      label: '0-16',
    },
    {
      value: '16-40',
      label: '16-40',
    },
    {
      value: '+40',
      label: '+40',
    },
  ]
  const optionsDaysPerWeekSelect = [
    {
      value: '5',
      label: '5',
    },
    {
      value: '6',
      label: '6',
    },
  ]

  return (
    <Stack>
      <Grid>
        <Grid.Col span={mobileView ? 6 : 4}>
          <CheckboxFieldController
            control={control}
            name={'living_in'}
            id={'living_in'}
            label={t('living_in')}
            mt={'xs'}
          />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={mobileView ? 6 : 4}>
          {watchLivingIn ? (
            <SelectController
              control={control}
              name={'days_per_week'}
              id={'days_per_week'}
              label={t('days_per_week')}
              size={mobileView ? 'md' : 'lg'}
              placeholder={t('days_per_week')}
              mb={'md'}
              data={optionsDaysPerWeekSelect}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          ) : (
            <SelectController
              control={control}
              name={'hours_per_week'}
              id={'hours_per_week'}
              label={t('hours_per_week')}
              size={mobileView ? 'md' : 'lg'}
              placeholder={t('hours_per_week')}
              mb={'md'}
              data={optionsHoursPerWeekSelect}
              styles={
                mobileView
                  ? {
                      label: {
                        fontWeight: 'bold',
                        fontSize: '16px !important',
                      },
                    }
                  : {}
              }
            />
          )}
        </Grid.Col>
      </Grid>
    </Stack>
  )
}
