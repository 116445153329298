import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentIntentResult, StripePaymentElementOptions } from '@stripe/stripe-js'
import classNames from 'classnames'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Anchor, Space } from '@mantine/core'
import { Checkbox as MantineCheckbox, Text } from '@mantine/core'
import { Button } from '@/components/Elements'
import { EXTERNAL_LINK } from '@/const/core'
import { useAppState } from '@/features/app/hooks'
import { bookingsAPI } from '@/features/bookings/api'
import { BOOKING_TYPE } from '@/features/bookings/consts/booking'
import { getType } from '@/features/bookings/helpers/booking/type'
import { BookingModel } from '@/features/bookings/types/models'
import { useNotify } from '@/hooks'
import useStyles from './CheckoutForm.styles'

interface IProps {
  booking: BookingModel
  paymentId: any
}
export default function CheckoutForm({ booking, paymentId }: IProps) {
  const { t } = useTranslation()
  const { showNotification } = useNotify()

  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState<any>(null)
  const [accepted, setAccepted] = useState(false)
  const [showAcceptError, setShowAcceptedError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { classes } = useStyles()
  const navigate = useNavigate()
  const canChangePageRef = useRef<boolean>(false)

  const {
    appState: { mobileView },
  } = useAppState()

  const goNext = () => {
    canChangePageRef.current = true
    navigate('/booking/' + getType(booking.type).path + '/care-details', { replace: true })
  }

  useEffect(() => {
    const alertPageChange = (e: any) => {
      if (!canChangePageRef.current) {
        e.preventDefault()
        e.returnValue = ''
        return
      }
    }

    window.addEventListener('beforeunload', alertPageChange)

    return () => {
      window.removeEventListener('beforeunload', alertPageChange)
    }
  }, [])

  const submit = async (e: FormEvent) => {
    e.preventDefault()

    if (!accepted) {
      showNotification({
        type: 'error',
        message: t('terms_must_be_accepted'),
      })
      setShowAcceptedError(true)
      return
    } else {
      setShowAcceptedError(false)
    }
    if (isLoading || !stripe || !elements) {
      console.log('stripe: ' + !stripe + ' elements ' + !elements)
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    try {
      const { error, paymentIntent }: PaymentIntentResult = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/booking/confirmed`,
        },
        redirect: 'if_required',
      })

      if (error?.type === 'card_error' || error?.type === 'validation_error') {
        setMessage(error?.message)
      } else {
        await bookingsAPI.setBookingConfirmed(booking.id, paymentId)
        goNext()
        //setOcasionalPaymentDone(true)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const options: StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  }

  return (
    <form id="payment-form" onSubmit={submit}>
      <PaymentElement options={options} id="payment-element" />

      <div className={mobileView ? 'mt-3' : ' mt-3'}>
        <MantineCheckbox
          checked={accepted}
          onChange={(event) => setAccepted(event.currentTarget.checked)}
          label={
            <>
              {t('register_terms_label')}{' '}
              <Anchor component={'a'} href={EXTERNAL_LINK.TERMS_AND_CONDITIONS} target={'_blank'}>
                {t('register_terms_text')}
              </Anchor>
            </>
          }
          mb={'md'}
        />
        {showAcceptError && (
          <Text size={13} color={'red'}>
            {t('terms_must_be_accepted')}
          </Text>
        )}
      </div>

      {mobileView ? (
        <>
          <Space h={70} />
          <div
            className={classNames(
              classes.footerPriceMobile,
              'flex',
              'justify-between',
              'items-center',
              {
                'sticky-footer': mobileView,
              }
            )}
          >
            <div>
              <h2
                className="price-calculation-tooltip flex gap-4 items-center cursor-pointer"
                data-pr-position="top"
              >
                <span className={classes.price}>
                  {t(booking.type === BOOKING_TYPE.REGULAR ? 'price.1st_month' : 'price')}
                </span>
              </h2>
              <h2
                className="price-calculation-tooltip flex gap-4 m-0 items-center cursor-pointer"
                data-pr-position="top"
              >
                <span>€{((booking.total_amount || 0) / 100).toFixed(2)}</span>
              </h2>
            </div>
            <div className="mt-4 mb-2">
              <Button loading={isLoading} disabled={!stripe || !elements} fullWidth type="submit">
                {t('pay')}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <div className="mt-8">
          <Button
            loading={isLoading}
            disabled={isLoading || !stripe || !elements}
            fullWidth
            type="submit"
          >
            {t('pay')}
          </Button>
        </div>
      )}
      {/* Show any error or success messages */}
      {message && (
        <div className=" text-primary-500 text-sm mt-1 font-quicksand" id="payment-message">
          {message}
        </div>
      )}
    </form>
  )
}
